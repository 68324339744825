//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "ClientsOverview",
  data() {
    return {
      clients: [
        {
          id: 1,
          name: "Health Providers",
          link: "/clients/health-providers",
        },
        {
          id: 2,
          name: "Payers and Insurers",
          link: "/clients/payers-insurers",
        },
      ],
    };
  },
};
