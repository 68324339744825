//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Header from "./../layouts/Header.vue";
import HeroHome from "./../components/HeroHome.vue";
import FeaturedArchitecture from "../components/FeaturedArchitecture.vue";
import Footer from "./../layouts/Footer.vue";
import FeaturedProducts from "./../components/FeaturedProducts.vue";
import HeroPageBanner from "./../components/HeroPageBanner.vue";
import CtaBanner from "./../components/CtaBanner.vue";
import FeaturedBlocks from "./../components/FeaturedBlocks.vue";
import Architecture from './../components/Architecture.vue';
import ClientsOverview from './../components/ClientsOverview.vue';

export default {
  name: "home",
  components: {
    Header,
    Architecture,
    HeroHome,
    FeaturedArchitecture,
    Footer,
    FeaturedProducts,
    HeroPageBanner,
    CtaBanner,
    FeaturedBlocks,
    ClientsOverview
  },
  head: {
    title: "Digital Healthcare Transformation | Mazecare",
    meta: [
      {
        name: "keywords",
        content:
          "Digital Healthcare Transformation, healthtech, digital healthcare, digital health platform, digital healthcare platform, health claim management system, clinic management system, hospital management system, lab management system, pharmacy management system",
      },
      {
        hid: "description",
        name: "description",
        content:
          "Digital healthcare transformation, software development & consultancy for the healthcare industry. Let's build your Digital Healthcare Platform together. Health Claim Managemen, Clinic Management System, Hospital Management System, and more!",
      },
    ],
  },
  jsonld() {
    return {
      "@context": "https://schema.org",
      "@type": "Corporation",
      name: "Mazecare",
      alternateName: "Mazecare Limited",
      url: "https://www.mazecare.com",
      logo: "https://drive.google.com/uc?export=view&id=1XPyBOb2BPNIVLbQ1z_4WTSpJfiDHhVd1",
      sameAs: [
        "https://www.facebook.com/Mazecare",
        "https://www.linkedin.com/company/mazecare",
        "https://github.com/mazecare",
        "https://www.mazecare.com",
      ],
    };
  },
};
