//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


export default {
  name:'CtaBanner',
  props: ["textlineOne", "textlineTwo","subTagline" , "btnText"]
};
