import { render, staticRenderFns } from "./index.vue?vue&type=template&id=259ba425&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {HeroHome: require('/app/components/HeroHome.vue').default,FeaturedProducts: require('/app/components/FeaturedProducts.vue').default,ClientsOverview: require('/app/components/ClientsOverview.vue').default,FeaturedBlocks: require('/app/components/FeaturedBlocks.vue').default,FeaturedArchitecture: require('/app/components/FeaturedArchitecture.vue').default,CtaBanner: require('/app/components/CtaBanner.vue').default})
