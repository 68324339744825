//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "FeaturedProducts",
  data() {
    return {
      healthproviders: [
        {
          id: 1,
          productname: "Hospital Management System",
          link: "/products/hospital-management-system",
          category: "Health Providers",
        },
        {
          id: 2,
          productname: "Clinic Management System",
          link: "/products/clinic-management-system",
          category: "Health Providers",
        },
        {
          id: 3,
          productname: "Laboratory Management System",
          link: "/products/lab-management-system",
          category: "Health Providers",
        },
        {
          id: 4,
          productname: "Pharmacy Management System",
          link: "/products/pharmacy-management-system",
          category: "Health Providers",
        },
       
      ],
      payers: [
        {
          id: 1,
          productname: "Health Claim Management System",
          link: "/products/health-claim-management-system",
          category: "Payers & Insurers",
        },
        {
          id: 2,
          productname: "Payor System",
          link: "/products/payor-system",
          category: "Payers & Insurers",
        },
      ],
      patients: [
        {
          id: 1,
          productname: "Patient/Member Portal",
          link: "/products/patient-member-portal",
          category: "Patients",
        },
      ]
    };
  },
};
